import Swiper from 'swiper/dist/js/swiper.min';

// BRANDBAR SWIPPER
// ------------------------------------------------------
jQuery(document).ready(function() {
  var swiperPopularShopsDefaultConfig = {
    slidesPerView: 'auto',
    visibilityFullFit: true,
    autoResize: false,
    preloadImages: true
  }
  var swiperPopularShopsColorReverseConfig = {
    slidesPerView: 10,
    spaceBetween: 5,
    visibilityFullFit: true,
    autoResize: false,
    preloadImages: true
  }
  var swiperPopularShops = new Swiper('#swipper-brand', $('#swipper-brand').hasClass('color-reverse') ? swiperPopularShopsColorReverseConfig : swiperPopularShopsDefaultConfig);


  var activeSlide = $('#popularList .swiper-slide-active');
  var popularListDefaultConfig = {
      slidesPerView: 5,
      spaceBetween: 0,
      loop: true,
      centeredSlides: true,
      preloadImages: true,
      lazy: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        1200: {
          slidesPerView: 4
        },
        991: {
          slidesPerView: 3
        },
        680: {
          slidesPerView: 2
        },
        480: {
          slidesPerView: 1
        }
      }
    };
  var swiperPopularShops = new Swiper('#popularList', popularListDefaultConfig);

  activeSlide.addClass('slide-current');
  activeSlide.next().addClass('.slide-current');
  activeSlide.prev().addClass('.slide-current');

  // HOEMPAGE RECOMENDED OFFERS
  var recomendedOffersConfig = {
    slidesPerView: 5,
    visibilityFullFit: true,
    autoResize: false,
    preloadImages: true,
    centeredSlides: true,
    lazy: true,
    loop: true,
    autoplay: 5000,
    spaceBetween: 15,

    // SWIPER SLIDES PER BREAKPOINT
    breakpoints: {
      1369: {
        slidesPerView: 5,
        centeredSlides: true
      },
      1200: {
        slidesPerView: 5,
        centeredSlides: true
      },
      991: {
        slidesPerView: 4,
        centeredSlides: true
      },
      768: {
        slidesPerView: 4,
        centeredSlides: true
      },
      480: {
        slidesPerView: 3,
        centeredSlides: true
      },
      400: {
        slidesPerView: 2,
        centeredSlides: false
      },
      300: {
        slidesPerView: 2,
        centeredSlides: false
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  };
  var swiperRecomendedOffers = new Swiper('#recomended_offers', recomendedOffersConfig);
  var activeSlide = $('#recomended_offers .swiper-slide-active');

  activeSlide.addClass('slide-current');
  activeSlide.next().addClass('.slide-current');
  activeSlide.prev().addClass('.slide-current');
});