jQuery(document).ready(function() {
  var items = $('#mainNav').html()
  var itemsCollapse = $('#mainNavCollapse').html()

  function toggleMenuOnMobile () {
    if ($(this).innerWidth() <= 752) {
      if ($('#mainNavCollapse li').length <= 2) {
        $('#mainNav li:not(:last)').each(function () {
          var el = $(this)
          if (!el.hasClass('always-visible')) $('#mainNavCollapse').prepend(el)
        })
      }
    } else if ($(this).innerWidth() > 752) {
      if ($('#mainNav li').length === 2) {
        $('#mainNav li').remove()
        $('#mainNav').append(items)
        $('#mainNavCollapse li').remove()
        $('#mainNavCollapse').append(itemsCollapse)
      }
    }
  }

  if ($(window).innerWidth() <= 752) toggleMenuOnMobile()

  $(window).resize(toggleMenuOnMobile)

})