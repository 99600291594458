import AdBlockEnabled from '../../base'

export default function OfferModalClass(offer, html) {
    this.$html = $(html);
    this.$element = null;
    this.offer = offer;
}

OfferModalClass.prototype.append = function () {
    if (!this.isAppended()) {
        $('body').append(this.$html);
        this.$element = $('#' + this.$html.attr('id'));

        if (!AdBlockEnabled()) {
            this.hideAdblockMessage();
        }
    }
    return this;
};

OfferModalClass.prototype.show = function () {
    if (this.isAppended()) {
        this.$element.modal('show');
        this.bindHideBackgroundListener();
    }
    return this;
};

OfferModalClass.prototype.hide = function () {
    if (this.isAppended()) {
        this.$element.modal('hide');
        this.unbindHideBackgroundListener();
    }
    return this;
};

OfferModalClass.prototype.bindHideBackgroundListener = function () {
    var that = this;
    $('body').on('click', '.modal-apla', function () {
        that.hide();
    });
    return this;
};

OfferModalClass.prototype.unbindHideBackgroundListener = function () {
    $('body').off('click', '.modal-apla');
    return this;
};

OfferModalClass.prototype.remove = function () {
    if (this.isAppended()) {
        this.$element.remove();
        this.$element = null;
    }
    return this;
};

OfferModalClass.prototype.hideAdblockMessage = function () {
    var $adblockMessage = this.$element.find('.adb-info');
    if ($adblockMessage.length) {
        $adblockMessage.addClass('hidden');
    }
};

OfferModalClass.prototype.isAppended = function () {
    return this.$element !== null;
};