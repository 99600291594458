import OfferModalClass from './class/offer-modal-class'
import Offer from './class/offer-class'

export default function OfferModalLoader() {
}

OfferModalLoader.prototype.get = function get(offer, resolve, reject) {
    this.getHtml(offer, function (html) {
        var modalInstance = new OfferModalClass(offer, html);
        resolve(modalInstance);
    }, reject);
};

OfferModalLoader.prototype.endpointUrl = function endpointUrl() {
    return $('body').data('homepage-url');
};

OfferModalLoader.prototype.getHtml = function getHtml(offer, resolve, reject) {
    var data = {};
    if (Offer === offer.constructor) {
        data.cid = offer.getOfferId();
    }

    $.ajax({
        url: this.endpointUrl(),
        dataType: 'html',
        data: data,

        success: function (html) {
            if (html.length > 0) {
                resolve(html);
            } else {
                if (reject != null) {
                    reject();
                }
            }
        },

        fail: function () {
            if (reject != null) {
                reject();
            }
        }
    });
};