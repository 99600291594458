// Offer countdown
// -----------------------------------------------------------------------
jQuery(document).ready(function() {
    showTime();

    function showTime() {
        $('[data-countdown]').each(function() {
            const expireDate = $(this).data('countdown');
            const expireDateParsed = Date.parse(expireDate);
            const now = Date.now();
            let html;

            const timeDiff = expireDateParsed - now;
            if(timeDiff < 0) {
                $(this).closest('.offer_box').remove();
                return;
            }

            let days = timeDiff / (24 * 3600 * 1000);
            let hours = days%1 * 24;
            let minutes = hours%1 * 60;
            days = Math.floor(days);
            hours = Math.floor(hours);
            minutes = Math.floor(minutes);

            if (days >= 30) { // more than 30 days
                html = `<i class="ico-svg clock"></i> ${moreDay}`;
            } else if (days >= 1) { // minutes
                html = `<i class="ico-svg clock"></i> ${days} ${dayLang} ${theEnd}`;
            } else if (days < 1 && hours >= 1) { // hours
                html = `<i class="ico-svg clock"></i> ${hours} ${hourLang} ${minutes} ${minLang} ${theEnd}`;
            } else if (hours < 1) { // minutes
                html = `<i class="ico-svg clock"></i> ${minutes} ${minLang} ${theEnd}`
            }

            // Text is red if days < 4
            if (days < 4) {
                $(this).addClass("text-primary");
            }

            $(this).html(html);
        })
    }
});