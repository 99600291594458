import Clipboard from 'clipboard';
import toastr from 'toastr';

// Copy code to the clipboard
// -----------------------------------------------------------------------
jQuery(document).ready(function() {

    var clipboard = new Clipboard('#copyBtn');

    clipboard.on('success', function() {
        toastr['success']($('#copyBtn').data('copy-text'));
    });

    clipboard.on('error', function() {
        toastr['warning']($('#copyBtn').data('no-copy-text'));
    });

});