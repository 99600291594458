import OfferModalLoader from './offer-modal-loader'
import Offer from './class/offer-class'
import OfferElementError from './class/error/offer-element-error'

export default function OfferHandler(offerModalLoader, metricBaseUrl) {
    if (window.$ == null) {
        throw new Error('OfferHandler\'s JavaScript requires jQuery');
    }

    this.offerModalLoader = offerModalLoader;
    this.metricBaseUrl = metricBaseUrl + this.getLang();
    this.idParam = 'cid';
    this.offersStorage = {};
    this.appendedModalsStorage = {};
}

OfferHandler.prototype.createListeners = function () {
    var hashCid = this.getHashCid();
    if (hashCid) {
        var offer = this.getOfferByHashCid(hashCid);
        if (offer) {
            this.showModal(offer);
        }
    }

    var that = this;
    this.offerClickListener(function (offer) {
        if (offer) {
            if (offer.isPromoType() || offer.isGiftcardType()) {
                that.openTab(that.getMetricRedirectUrl(offer));
                that.redirectTo(that.getOfferRedirectUrl(offer));
            } else {
                that.openTab(that.getOfferRedirectUrl(offer));
                that.redirectTo(that.getMetricRedirectUrl(offer));
            }
            that.showModal(offer);
        }
    });
};

OfferHandler.prototype.getOfferByHashCid = function (hashCid) {
    return this.getOffer(hashCid);
};

OfferHandler.prototype.offerClickListener = function (callback) {
    var that = this;
    $('body').on('click', '[data-action="showoffer"]', function (event) {
        event.preventDefault();
        var elementId = $(this).attr('id');
        if (!elementId) {
            elementId = $(this).parent().attr('id'); // homepage
        }
        var idParts = elementId.split('_');
        var offerId = idParts[2];

        callback(that.getOffer(offerId));
    });
};

OfferHandler.prototype.getOffer = function (offerId) {
    var offer = this.offersStorage[offerId];
    if (offer == null) {
        try {
            offer = new Offer(offerId);
            this.offersStorage[offerId] = offer;
        } catch (e) {
            if (e.name !== OfferElementError.name) {
                throw e;
            }
            return null;
        }
    }

    return offer;
};

OfferHandler.prototype.showModal = function (offer) {
    this.getAppendedModal(offer, function (offerModal) {
        offerModal.show();
    });
};

OfferHandler.prototype.getAppendedModal = function (offer, callback) {
    var unique = offer.getUnique();
    var appendedModal = this.appendedModalsStorage[unique];
    if (appendedModal) {
        callback(appendedModal);
    } else {
        this.appendModal(offer, callback);
    }
};

OfferHandler.prototype.appendModal = function (offer, callback) {
    var that = this;
    this.getModal(offer, function (offerModal) {
        var unique = offer.getUnique();
        that.appendedModalsStorage[unique] = offerModal;
        callback(offerModal.append());
    });
};

OfferHandler.prototype.getModal = function (offer, callback) {
    this.offerModalLoader.get(offer, function (offerModal) {
        callback(offerModal);
    });
};

OfferHandler.prototype.getOfferRedirectUrl = function (offer) {
    var questionMarkCid = this.getCidByUrl(offer.getUrl(), '?');
    if (questionMarkCid) {
        return this.generateCidUrl(questionMarkCid, '?')
    }
    var hashCid = this.getCidByUrl(offer.getUrl(), '#');
    if (hashCid) {
        return this.generateCidUrl(hashCid, '#');
    }
    return null;
};

OfferHandler.prototype.getCidByUrl = function (url, character) {
    if (url.indexOf(character + this.idParam + '=') >= 0) {
        return url.split(character + this.idParam + '=').pop();
    }
    return null;
};

OfferHandler.prototype.generateCidUrl = function (cid, character) {
    var localPath = document.location.origin + document.location.pathname;
    return localPath + character + this.idParam + '=' + cid;
};

OfferHandler.prototype.getMetricRedirectUrl = function (offer) {
    return this.metricBaseUrl + offer.getMetricPath();
};

OfferHandler.prototype.redirectTo = function (url) {
    document.location.href = url;
};

OfferHandler.prototype.openTab = function (url) {
    window.open(url, '_blank');
};

OfferHandler.prototype.getLang = function () {
    return $('body').data('lang');
};

OfferHandler.prototype.getHashCid = function () {
    var locationHash = window.location.hash;
    if (locationHash != null) {
        var hash = locationHash.trim();
        if (hash.length > 0 && hash.indexOf(this.idParam + '=') === 1) {
            var offerId = hash.split(this.idParam + '=').pop();
            var hashReproduction = '#' + this.idParam + '=' + offerId;
            if (hash === hashReproduction) {
                return offerId;
            }
        }
    }
    return null;
};

jQuery(document).ready(function () {
    var offerModalLoader = new OfferModalLoader();
    var offerHandler = new OfferHandler(offerModalLoader, PICOMETRIC_PATH);
    offerHandler.createListeners();
});
