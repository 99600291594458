
// Share
//-------------------------------------------------------------
function facebookShare() {

    var urlfb = encodeURIComponent($(this).attr('data-fb-url'));
    window.open( 'http://www.facebook.com/sharer.php?u='+urlfb+' ', 'popUpWindow','height=700,width=800,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')

}
$('body').on('click', '.facebookShare', facebookShare);


function twShare() {

    var urltw = encodeURIComponent($(this).attr('data-tw-url')),
        titletw = $(this).attr('data-tw-title');

    window.open(

        'https://twitter.com/intent/tweet?url='+urltw
    );
}
$('body').on('click', '.twShare', twShare);


function vkShare() {
    var urlvk = encodeURIComponent($(this).attr('data-vk-url'));
    window.open(
        'http://vk.com/share.php?url='+urlvk
    );
}
$('body').on('click', '.vkShare', vkShare);


function okShare() {

    var urlok = encodeURIComponent($(this).attr('data-ok-url')),
        titleok = $(this).attr('data-ok-title');
    window.open(
        'https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl='+urlok+'&title='+titleok
    );
}
$('body').on('click', '.okShare', okShare);


function gpShare() {

    var urlgp = encodeURIComponent($(this).attr('data-gp-url'));
    window.open(
        'https://plus.google.com/share?url='+urlgp
    );
}
$('body').on('click', '.gpShare', gpShare);


function piShare() {

    var urlpi = encodeURIComponent($(this).attr('data-pi-url')),
        titlepi = $(this).attr('data-pi-title');
    window.open(
        'https://pinterest.com/pin/create/bookmarklet/?&url='+urlpi+'&description='+titlepi
    );
}
$('body').on('click', '.piShare', piShare);


function rdShare() {

    var urlrd = encodeURIComponent($(this).attr('data-rd-url')),
        titlerd = $(this).attr('data-rd-title');
    window.open(
        'https://reddit.com/submit?url='+urlrd+'&title='+titlerd
    );
}
$('body').on('click', '.rdShare', rdShare);
