import toastr from 'toastr';

// Toastr
// ---------------------------------------------------------------------
toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-bottom-center",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "200",
    "hideDuration": "500",
    "timeOut": "2500",
    "extendedTimeOut": "500",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

if (
    ( typeof notyMessage  === 'undefined' || typeof notyType  === 'undefined' )
    &&
    ( notyMessage  != '' && notyType  != '' )
) { toastr[notyType](notyMessage); }