import Cookies from 'js-cookie';

const PICOMETRIC_EVENT_TYPE_CUSTOM = 'CUSTOM';

const PICOMETRIC_LABEL_DISPLAY_SHOPS = 'display_shops';
const PICOMETRIC_LABEL_DISPLAY_SHOP = 'display_shop';
const PICOMETRIC_LABEL_DISPLAY_TOP = 'display_top';
const PICOMETRIC_LABEL_DISPLAY_SEARCH = 'display_search';

jQuery(document).ready(function() {
    var picometricTrackingOn = $('body').data('picometric-tracking-on');
    if (picometricTrackingOn) {
        sendTrackingRequests();
    }
});

function sendTrackingRequests() {
    var picometricEventType = $('#picodi-wl').data('picometric-event-type');
    var picometricLabel = $('#picodi-wl').data('picometric-label');

    if (picometricEventType === PICOMETRIC_EVENT_TYPE_CUSTOM && picometricLabel === PICOMETRIC_LABEL_DISPLAY_SHOP) {
        sendTrackingRequest(prepareRequestData(true));
    } else {
        sendTrackingRequest(prepareRequestData(false));
    }
}

function sendTrackingRequest(requestData) {
    $.ajax({
        url: prepareRequestUrl(requestData),
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true,
        type: 'POST',
        data: requestData
    });
}

function prepareRequestUrl(data) {
    var body = $('body');
    var lang = body.data('lang');
    var picometricPath = body.data('picometric-path');
    var picometricGetMode = body.data('picometric-get-mode');
    var url = picometricPath + lang + '/t';
    if (picometricGetMode) {
        var allowedParameters = getAllowedGetParameters();
        var getParameters = '?';
        $.each(data, function(index, value) {
            if (allowedParameters.indexOf(index) !== -1) {
                if($.inArray(index, ['uri_str', 'referrer']) !== -1) {
                    value = encodeURIComponent(value);
                }
                getParameters += index + '=' + simplifyParameters(value) + '&';
            }
        });
        url += getParameters.slice(0, -1);
    }

    return url;
}

function prepareRequestData(extendedData) {
    var returnData = getDefaultRequestData();
    var wlSection = $('#picodi-wl');

    if (extendedData && void(0) !== wlSection) {
        var picometricEventType = void(0) !== wlSection.data('picometric-event-type') ? wlSection.data('picometric-event-type') : null;
        var picometricEventLabel = void(0) !== wlSection.data('picometric-label') ? wlSection.data('picometric-label') : null;
        var picometricEventData = void(0) !== wlSection.data('picometric-data') ? wlSection.data('picometric-data') : null;

        returnData.event = picometricEventType;
        returnData.label = picometricEventLabel;
        returnData.data = picometricEventData;
    }

    return returnData;
}

function getDefaultRequestData() {
    return {
        referrer: document.referrer,
        uri_str: document.URL,
        uid: null,
        ga: void(0) !== Cookies.get('_ga') ? Cookies.get('_ga') : null,
        st_id: getObjectId()
    };
}

function simplifyParameters(value) {
    var eventMap = {
        CUSTOM: "c",
        VISIT: "v",
        SALE: "s",
        COUPON_REDIRECT: "c",
        SHOP_REDIRECT: "s",
        OTHER_REDIRECT: "o",
        PRODUCT_REDIRECT: "p",
        PARTNER_REDIRECT: "pa",
        CROSS_COUPON_REDIRECT: "ccr"
    };

    if (value === null) {
        return '';
    }

    value = 'object' === typeof value ? JSON.stringify(value) : value;

    return void(0) !== eventMap[value] ? eventMap[value] : value;
}

function getAllowedGetParameters() {
    return [
        'type', 'uid', 'ga', 'event', 'label', 'data', 'subtype', 'object_id', 'st_id', 'referrer', 'uri_str'
    ];
}

function ObjectId() {
    var m = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Math;
    var d = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Date;
    var h = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 16;
    var s = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function (s) {
        return m.floor(s).toString(h);
    };
    return s(d.now() / 1000) + ' '.repeat(h).replace(/./g, function () {
        return s(m.random() * h);
    });
}

function getObjectId() {
    var lang = $('body').data('lang');
    var cookieName = 'picodi_' + lang + '_st';
    if (Cookies.get(cookieName) === undefined) {
        Cookies.set(cookieName, ObjectId(), {expires: 180});
    }
    return Cookies.get(cookieName);
}