import lozad from 'lozad';
import 'bootstrap-sass/assets/javascripts/bootstrap.min';


// SEARCH FORM DROPDOWN - MOBILE
//------------------------------------------------
var search = $('.search-control');
search.on('click', function () {
    $(this).find('.fa').toggleClass('fa-search fa-cancel');
    $('.navbar-form').slideToggle('fast');
});



// SERACH FORM - PREVENT EMPTY REDIRECT
//--------------------------------------------------------
$('.navbar-form').on('submit', function (e) {
    var serachInput = $(this).find('input[name=s]');
    if(serachInput.val().trim() === ''){
        e.preventDefault()
    }
});

// ACTIVE FILTERS MOBILE BTN
//-------------------------------------------------------------------------

$('#show-filters').on('click',function () {
    $('.filters-aside').toggleClass('active');
    console.log("filters");
});



//CLOSE MODAL
//------------------------------------------------

$('.modal .close').on('click', function () {
    $('.modal').modal('hide');
});
$('.modal-apla').on('click',function () {
    $('.modal').modal('hide');
});




//  Data-url works as href
// ------------------------------------------------------------------------
$(".use-link").click(function(e){
    e.preventDefault();
    var prod_url = $(this).attr("data-url");
    var win = window.open(prod_url, '_self');
    win.focus();
});

// OBSERVE LOZAD
lozad('.lozad', {
    load: function(el) {
        el.src = el.dataset.src;
        el.onload = function() {
            $(this).parent().removeClass('loader')
            el.classList.add('fade-logo');
        }
    }
}).observe();


// Arrow rotate
// -----------------------------------------------------------------------------
$(".accordion_title.has-siblings").click(function(event) {
    event.preventDefault();

    if ( $(this).hasClass('is-active') ) {
        $(".accordion_title.has-siblings").removeClass('is-active');
    } else {
        $(".accordion_title.has-siblings").removeClass('is-active');
        $(this).addClass('is-active');
    }

});



// Responsive video
//---------------------------------------------------------------------------------------

$(".shop-more-info iframe").addClass("embed-responsive-item").wrap( "<div class='embed-responsive embed-responsive-16by9'></div>" );



// SCROLL PAGE IMAGE
//----------------------------------------------------------------------------------------

var scrollLoad = false;

$(document).scroll(function(event) {

    if (scroll >= 500 && scrollLoad === false) {

        $( ".category-block .effect-bubba" ).each(function(element) {

            var imageDataSource = $(this).data('src');

            if (imageDataSource !== "undefined"){

                var bgSrc = imageDataSource;
                $(this).attr('style', bgSrc);

            }

        });

        scrollLoad = true;
        return false;

    }

    var scroll = $(this).scrollTop();

    if (scroll >= 800 && scrollLoad === false) {

        $( ".shop-more-info iframe" ).each(function(element) {

            var videoDataSource = $(this).data('src');

            if (videoDataSource !== "undefined"){

                var videoSrc = videoDataSource;
                $(this).attr('src', videoSrc);

            }

        });

        $( ".shop-more-info img" ).each(function(element) {

            var imageDataSource = $(this).data('src');

            if (imageDataSource !== "undefined"){
                var imgSrc = imageDataSource;
                $(this).attr('src', imgSrc);
            }

        });
        scrollLoad = true;
        return false;
    }

});

export default function AdBlockEnabled () {
    var ad = document.createElement('ins');
    ad.className = 'AdSense';
    ad.style.display = 'block';
    ad.style.position = 'absolute';
    ad.style.top = '-1px';
    ad.style.height = '1px';
    document.body.appendChild(ad);
    var isAdBlockEnabled = !ad.clientHeight;
    document.body.removeChild(ad);

    return isAdBlockEnabled;
}

// Service Worker
window.addEventListener('load', function () {
    if (!navigator.serviceWorker) {
        return
    }

    var swPath = $('body').attr('data-wl-url');
    navigator.serviceWorker.register(swPath + 'sw.js', {}).catch(function (err) {
        console.log('SW registration failed', err)
    })
});

// position: sticky stuck state detection for css
const list = $('.letter-list');
if (list.length) {
    const topOffset = $(list).offset().top - $(list).outerHeight();

    const makeSticky = function () {
        const scrollTop = $(window).scrollTop();

        if (scrollTop > topOffset) {
            $(list).addClass('sticky');
        } else {
            $(list).removeClass('sticky');
        }
    };

    makeSticky();

    $(window).scroll(function () {
        makeSticky();
    });
}

//hiding and showing scroll to top button
$(window).scroll(function() {
    if ($(window).scrollTop() > 400) {
        $("#scroll-top").addClass('visible');
    }
    else {
        $("#scroll-top").removeClass('visible');
    }
});