// GET FILTERS
//-------------------------------------------------------------------------------

var filters = $('#category-filters');

$.urlParam = function(name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results==null){
        return null;
    }
    else{
        return decodeURI(results[1]) || 0;
    }
}

if  ($.urlParam('category') != null) {
    filters.removeClass('hidden');

    $('.shop-list .nav-links_item a').each(
        function() {
            var activeUrl = '?category=' + $.urlParam('category');
            if (activeUrl== $(this).attr('href')){
                $(this).addClass('active');
                var catname =  $(this).text();
                filters.find('.filters-selected').append('<li class="filters-selected_item"><a class="filtr">' + catname + ' <span class="remove">x</span></a></li>');
            }
        }
    );
}

$('.filters-selected li a .remove').on('click',function (e) {
    window.location.href = window.location.href.split('?')[0];
});