import OfferElementError from './error/offer-element-error'

export default function Offer(offerId) {
    this.offerId = parseInt(offerId);
    this.$element = this.getElement();
}

Offer.prototype.getElement = function () {
    var $element = $('[id$="_' + this.offerId + '"]');
    if (!$element.length) {
        throw new OfferElementError(this.offerId);
    }
    return $element;
};

Offer.prototype.getOfferId = function () {
    return this.offerId;
};

Offer.prototype.getUnique = function () {
    return this.$element.attr('id');
};

Offer.prototype.getPartnerId = function () {
    return this.$element.data('partner-id');
};

Offer.prototype.getPartnerOfferId = function () {
    return this.$element.data('partner-offer-id');
};

Offer.prototype.getUrl = function () {
    return this.$element.find('a').attr('href');
};

Offer.prototype.getMetricPath = function () {
    return '/wl/r/' + this.getOfferId() + '/' + this.getPartnerOfferId() + '/' + this.getPartnerId();
};

Offer.prototype.isCodeType = function () {
    return this.$element.hasClass('type-code');
};

Offer.prototype.isPromoType = function () {
    return this.$element.hasClass('type-promo');
};

Offer.prototype.isCouponType = function () {
    return this.$element.hasClass('type-coupon');
};

Offer.prototype.isGiftcardType = function () {
    return this.$element.hasClass('type-giftcard');
};